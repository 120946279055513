<template>
  <el-card id="authority">
    <header>
      <h1>权限管理</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>部门</span>
            <el-select v-model="departId" placeholder="请选择部门" size="small">
              <el-option
                v-for="item in departList"
                :key="item.id"
                :label="item.departName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>号码</span>
            <el-input
              v-model="username"
              placeholder="请输入号码"
              size="small"
            ></el-input>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            class="searchBtn"
            size="small"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            class="reset"
            size="small"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        height="450"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="账号名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="username"
          label="手机号码"
          align="center"
          v-if="encryption != -1"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号码"
          align="center"
          v-if="encryption == -1"
        ></el-table-column>
        <el-table-column
          prop="depart_name"
          label="部门"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="role_name"
          label="身份"
          align="center"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="500"
          align="center"
          style="dispaly: flex"
          v-if="roleName == '企业'"
        >
          <template #default="scope">
            <el-button
              type="primary"
              @click="changeDepart(scope)"
              v-if="roleName == '企业'"
              size="small"
              >变更部门</el-button
            >
            <el-button
              type="primary"
              @click="updateRole(scope)"
              v-if="roleName == '企业'"
              size="small"
              >修改权限</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </main>
    <!-- 修改权限弹窗 -->
    <el-dialog title="修改权限弹窗:" :visible.sync="updateSHow" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="权限">
          <el-select
            v-model="value"
            placeholder="请选择对应权限"
            @change="changeValue(value)"
            v-if="roleName == 'admin'"
          >
            <el-option
              v-for="item in roleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <el-select
            v-model="value"
            placeholder="请选择对应权限"
            @change="changeValue(value)"
            v-if="parentId == 'null'"
          >
            <el-option
              v-for="item in roleList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateSHow = false"
            >退 出</el-button
          >
          <el-button type="primary" @click="updateSubmit">确认提交</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 修改权限弹窗 -->
    <el-dialog title="调换部门" :visible.sync="changeShow" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="部门">
          <el-select v-model="changeId" placeholder="请选择部门">
            <el-option
              v-for="item in departList"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="changeShow = false"
            >退 出</el-button
          >
          <el-button type="primary" @click="changeSubmit">确认提交</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { userList } from "@/api/user";
import { sysDepartList, sysDepartUpdateDepartUser } from "@/api/sys_depart";
import { roleUpdateRole } from "@/api/role";
export default {
  data() {
    return {
      form: {},
      radio: null,
      role_id: null,
      updateSHow: false,
      changeShow: false,
      changeId: null,
      userId: null,
      username: null,
      tableData: [],
      departList: [],
      roleList1: [
        {
          value: "7",
          label: "组长",
        },
        {
          value: "8",
          label: "员工",
        },
      ],
      value: "",
      value1: "",
      departId: null,
      roleName: null,
      parentId: "",
      user_id: "",
      encryption: false,
    };
  },
  created() {
    this.roleName = localStorage.getItem("roleName");
    this.parentId = localStorage.getItem("parentId");
    this.encryption = localStorage.getItem("encryption");
    this.user_id = localStorage.getItem("id");
    this.getList();
    this.getDepart();
  },
  methods: {
    change(redio) {
      this.role_id = redio;
    },
    // 查询
    handleSearch() {
      this.getList();
    },
    // 重置
    reset() {
      this.departId = null;
      this.username = null;
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        username: this.username,
        departId: this.departId,
      };
      let res = userList(params);
      res.then((res) => {
        res.data.data.forEach((item) => {
          let str = item.username;
          item.phone = str.substr(0, 3) + "****" + str.substr(7);
        });
        this.tableData = res.data.data;
      });
    },
    // 部门展示
    getDepart() {
      let params = {
        page: 1,
        size: 30,
        topId: this.user_id,
      };
      let res = sysDepartList(params);
      res.then((res) => {
        this.departList = res.data.data;
      });
    },
    //修改权限按钮
    updateRole(row) {
      this.updateSHow = true;
      this.value = row.row.role_id;
      this.value1 = row.row.user_id;
      // console.log(row.row);
    },
    //选择框变值
    changeValue(value) {
      this.role_id = value;
    },
    //确认提交
    updateSubmit() {
      let params = {
        user_id: this.value1,
        role_id: this.role_id,
      };
      this.$confirm("确认修改权限吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let res = roleUpdateRole(params);
          res.then((res) => {
            if (res.data.statusCode == "00000") {
              Message.success(res.data.message);
              this.updateSHow = false;
              this.getList();
            } else {
              Message.error(res.data.message);
              this.updateSHow = false;
            }
          });
        })
        .catch(() => {
          Message.info("已取消修改");
        });
    },
    //变更部门
    changeDepart(row) {
      this.changeShow = true;
      // console.log(row.row.depart_id);
      this.userId = row.row.user_id;
      this.changeId = row.row.depart_id;
    },
    changeSubmit() {
      let params = {
        departId: this.changeId,
        userId: this.userId,
      };
      let res = sysDepartUpdateDepartUser(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.changeShow = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.changeShow = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#authority {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 21%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>